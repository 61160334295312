<template>
    <v-app style="background-color: #F2F2F2">
      <v-layout row wrap align-center justify-center v-if="loader === false">
        <v-flex xs12 lg7 class="ma-5">
          <v-card color="primary">
            <v-card-text class="pa-0">
              <v-layout
                row
                wrap
                align-center
                justify-center
                fill-height
                class="ma-0"
              >
                <v-flex lg6 class="text-center" v-if="resolutionScreen >= 500">
                  <img
                    src="@/assets/landinglogo.png"
                    width="70%"
                    height="70%"
                    alt="One Box"
                  />
                </v-flex>
                <v-flex lg6 style="background: white" class="pl-3 pr-3">
                  <v-card-text class="text-left">
                    <v-list two-line dense>
                      <v-list-item class="pl-0 pb-0">
                        <v-list-item-content>
                          <h1 style="color: #1976D2">Welcome Back !</h1>
                          <h4 style="color: #1976D2; line-height: 40px;">
                            ONE BOX - Storage by your side
                          </h4>
                        </v-list-item-content>
  
                        <v-list-item-action>
                          <v-menu transition="slide-y-transition" bottom>
                            <template v-slot:activator="{ on }">
                              <v-btn fab text v-on="on" x-small>
                                <flag :iso="defaultflag" :squared="false" />
                              </v-btn>
                            </template>
                            <v-list nav dense>
                              <v-list-item
                                v-for="(item, i) in language"
                                :key="i"
                                @click="changelang(item.data)"
                              >
                                <v-list-item-action>
                                  <flag :iso="item.flag" :squared="false" />
                                </v-list-item-action>
                                <v-list-item-title>{{
                                  $t(item.name)
                                }}</v-list-item-title>
                              </v-list-item>
                            </v-list>
                          </v-menu>
                        </v-list-item-action>
                      </v-list-item>
                    </v-list>
                  </v-card-text>
                  <v-card-text v-if="loginssooneid === false">
                    <b>{{ $t("username") }}</b>
                    <v-text-field
                      class="mt-2"
                      outlined
                      solo
                      hide-details
                      flat
                      color="#174966"
                      prepend-inner-icon="account_box"
                      dense
                      @keyup.enter="login"
                      :disabled="loading"
                      v-model.trim="username"
                    ></v-text-field>
                    <br />
                    <b>{{ $t("password") }}</b>
                    <v-text-field
                      class="mt-2"
                      prepend-inner-icon="lock"
                      outlined
                      solo
                      color="#174966"
                      flat
                      dense
                      hide-details
                      :disabled="loading"
                      v-model.trim="password"
                      @keyup.enter="login"
                      @click:append="showpassword = !showpassword"
                      :type="showpassword ? 'text' : 'password'"
                      :append-icon="showpassword ? 'mdi-eye' : 'mdi-eye-off'"
                    ></v-text-field>
                    <div class="pt-3 text-right">
                      <a
                        :href="urloneid + '/type_forgot_password'"
                        style="text-decoration: none;"
                        target="_blank"
                        >{{ $t("forgotpassword") }}</a
                      >
                    </div>
                  </v-card-text>
                  <v-card-text class="pt-0" v-if="loginssooneid === false">
                    <v-btn
                      color="primary"
                      block
                      class="elevation-1 white--text"
                      @click="login"
                      :disabled="loading"
                      :loading="loading"
                    >
                      <b>{{ $t("login") }}</b>
                    </v-btn>
                  </v-card-text>
                  <v-card-text v-else>
                    <br><br><br>
                    <v-btn
                      color="primary"
                      block
                      class="elevation-1 white--text"
                      @click="fn_loginoneid"
                      :disabled="loading"
                      :loading="loading"
                    >
                      <b>เข้าสู่ระบบด้วย ONE-ID SSO</b>
                    </v-btn>
                    <br><br><br>
                  </v-card-text>
                  <br />
                  <v-divider></v-divider>
                  <v-card-text class="text-center">
                    <!-- :href="urloneid + '/register'" -->
                    <span>
                      {{ $t("createaccount1") }}?
                      <a
                        style="text-decoration: none;"
                        @click="$router.push('/register')"
                        target="_blank"
                        >{{ $t("createaccount2") }}</a
                      >
                    </span>
                  </v-card-text>
                </v-flex>
              </v-layout>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
  
      <v-layout row wrap align-center justify-center v-else>
        <v-flex lg4 md5 sm7 xs7>
          <v-progress-linear
            background-color="light-blue lighten-4"
            color="success"
            indeterminate
            rounded
          ></v-progress-linear>
          <loginsso :display="dialogsso" />
        </v-flex>
      </v-layout>
  
      <div v-if="dialog === true && getcheck === false">
        <v-dialog v-model="dialog" scrollable max-width="400px">
          <v-card>
            <v-card-text style="height: 320px" class>
              <div class="ma-6 pa-0 text-center">
                <img
                  src="@/assets/notification.png"
                  width="100px"
                  height="100px"
                  alt="One Box"
                />
              </div>
              <div class="text-center">
                <h2>เรียนผู้ใช้งาน One Box ทุกท่าน</h2>
              </div>
              <div>
                <br />
                <span style="font-size: 16px;">
                  หากท่านเคย upload ข้อมูลไว้ใน One Box กรุณาย้ายข้อมูลของท่านจาก
                  One Box เดิม (
                  <a target="_blank" href="https://archivebox.one.th"
                    >archivebox.one.th</a
                  >) มาที่
                  <a target="_blank" href="https://onebox.thaimediafund.or.th">box.one.th</a>
                  ภายในวันที่ 30 เมษายน พ.ศ.2563
                </span>
              </div>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-text class="text-center pa-2">
              <v-checkbox
                v-model="checkshow"
                class="mx-2"
                label="ไม่ต้องการให้แสดงอีก"
              ></v-checkbox>
              <v-btn color="green darken-1" class="white--text" @click="show()"
                >รับทราบ</v-btn
              >
            </v-card-text>
          </v-card>
        </v-dialog>
      </div>
    </v-app>
  </template>
  <script>
  import Swal from "sweetalert2/dist/sweetalert2.js";
  import { mapGetters, mapState } from "vuex";
  import "sweetalert2/src/sweetalert2.scss";
  import VueCookies from "vue-cookies";
  const loginsso = () => import("../components/optional/dialog-loginsso.vue");
  
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000
  });
  
  export default {
    components: { loginsso },
    data: function() {
      return {
        loginssooneid: true,
        dialogsso: false,
        ssocode: "",
        intervalSSO: "",
        checkshow: false,
        language: [
          {
            flag: "us",
            name: "languages.english",
            data: "en"
          },
          {
            flag: "th",
            name: "languages.thai",
            data: "th"
          }
        ],
        urloneid: process.env.VUE_APP_ONEID,
        loader: true,
        loading: false,
        username: "",
        password: "",
        showpassword: false,
        dialog: false
      };
    },
    computed: {
      ...mapGetters({
        dataBusinessProfile: "dataBusinessProfile",
        dataAccesstoken: "dataAccesstoken",
        dataLoginDefault:"dataLoginDefault"
      }),
      getcheck() {
        let check = localStorage.getItem("show");
        if (check === "true") {
          return true;
        } else {
          return false;
        }
      },
      resolutionScreen() {
        switch (this.$vuetify.breakpoint.name) {
          case "xs":
            return 220;
          case "sm":
            return 400;
          case "md":
            return 500;
          case "lg":
            return 600;
          case "xl":
            return 800;
        }
      }
    },
    methods: {
      show() {
        if (this.checkshow === "true") {
          console.log(this.checkshow);
          localStorage.setItem("show", this.checkshow);
          this.dialog = "false";
        } else {
          localStorage.setItem("show", this.checkshow);
          this.dialog = "false";
        }
      },
      login() {
        this.loading = true;
        let payload = {
          username: this.username,
          password: this.password
        };
        // this.$router.push('/mydrive')
        this.$store
          .dispatch("authorize_login", payload)
          .then(msg => {
            // console.log(msg);
            this.loading = false;
            if (msg.status === "Access Granted") { 
              // ส่ง event ไปยัง Google Analytics
              this.$gtag.event('login', {
                event_category: "username",
              });

              console.log("this.dataLoginDefault",this.dataLoginDefault,this.dataLoginDefault["account_category"]); 
              if(this.dataLoginDefault !== "") {
                console.log("เข้า if"); 
                if(this.dataLoginDefault["account_category"] === "Business"){
                  console.log(this.dataLoginDefault["account_category"]);
                  this.loginbusiness_default();
                }
              }
              if ("goto" in this.$route.query) {
                this.loginbusiness(
                  this.$route.query.goto,
                  this.$route.query.taxid
                );
              } else {
                this.$router.push(this.$route.query.nextUrl);
              }
            } else if (msg.code === "LN001") {
              Toast.fire({
                icon: "error",
                title: "Username หรือ Password ไม่ถูกต้อง"
              });
            } else if (msg.code === "ER401") {
              Toast.fire({
                icon: "error",
                title: "Username หรือ Password ไม่ถูกต้อง"
              });
            } else if (msg.msg === "ER401: Authorization is wrong") {
              Toast.fire({
                icon: "error",
                title: "Username หรือ Password ไม่ถูกต้อง"
              });
              this.loader = false;
            } else {            
              Toast.fire({ icon: "error", title: msg.msg });
            }        
          })
          .catch(err => {
            console.log(err);
            Toast.fire({
              icon: "error",
              title: "ไม่สามารถเชื่อมต่อ OneBox ได้ กรุณาติดต่อผู้ดูแลระบบ "
            });
            this.loading = false;
          });
      },
      changelang(parameter) {
        localStorage.setItem("lang", parameter);
        this.$i18n.locale = parameter;
        let dflag = this.language.findIndex(v => v.data === parameter);
        this.defaultflag = this.language[dflag]["flag"];
      },
      async loginsso(code) {
        let payload = {
          token: code,
        };
        this.loader = true;
        this.axios
          .post(
            process.env.VUE_APP_SERVICE_AUTHORIZE_SSO + '/api/login_by_token_oneid',
            payload
          )
          .then((res) => {
            if (res.data.status == 'OK') {
              localStorage.setItem('token', res.data.result['accesstoken']);
              VueCookies.set('token', res.data.result['accesstoken']);
              VueCookies.set('token_auth', true);
              this.$store.dispatch('authorize_granted', res.data.result);
              if (this.dataLoginDefault !== '') {
                if (this.dataLoginDefault['account_category'] === 'Business') {
                  console.log(this.dataLoginDefault['account_category']);
                  this.loginbusiness_default();
                }
              } else {
                // this.$cookies.remove('ssocode')
                this.$router.push('/mydrive');
              }
            } else {
              this.$store.dispatch('authorize_denied');
              // this.$cookies.remove('ssocode')
              this.loader = false;
              // this.dialogsso = true
              // this.intervalSSO = setInterval(() => { this.ssocode = this.$cookies.get('ssocode') }, 800);
              Toast.fire({
                icon: 'error',
                title: 'ไม่สามารถเชื่อมต่อ OneBox ได้ กรุณาติดต่อผู้ดูแลระบบ ',
              });
            }
          })
          .catch((err) => {
            console.log(err);
            Toast.fire({
              icon: 'error',
              title: 'ไม่สามารถเชื่อมต่อ OneBox ได้ กรุณาติดต่อผู้ดูแลระบบ ',
            });
            this.loading = false;
          });
      },
      loginbusiness_default(){
        let payload = {
          taxid: this.dataLoginDefault.id_card_num,
          accesstoken: this.dataAccesstoken,
          biz_id:this.dataLoginDefault.id        
        };
        this.$store.dispatch("switchaccount_business", payload).then((msg) => {
        if (msg["status"] === "Business Access Denied") {        
          console.log("msg", msg);
          if (msg["msg"] === "ER401: Authorization is wrong") {
            this.$router.push("logout");
          } else {
            // this.$store.dispatch("switchaccount_citizen").then(msg => {
            //   this.$emit("loadfile");
            //   this.$emit("callstorage");
            //   this.$router.push({ name: "myfiles" });
            // });
          }
        } else {
          this.$router.push(this.$route.query.nextUrl);
          // this.$router.push({ path: "/directory/" + this.dataLoginDefault["my_folder_id"] });
          //this.$router.push({ name: "myfiles" });
        }
      });
      },
      loginbusiness(path, taxid) {
        this.loading = true;
        let payload = {
          taxid: taxid,
          accesstoken: this.dataAccesstoken
        };
        this.$store.dispatch("switchaccount_business", payload).then(msg => {
          if (msg["msg"] === "ER401: Authorization is wrong") {
            console.log(msg);
            this.loader = false;
          } else {
            const pobj = JSON.stringify(payload);
            //sessionStorage.removeItem(pobj);
            sessionStorage.setItem("biz_active", pobj);
            if (path !== null || path !== undefined || path === "") {
              this.$router.push({
                name: path,
                query: {
                  type: 2,
                  sourcesystem: "ETAX",
                  systemid: this.$route.query.systemid,
                  fromdate: this.$route.query.crefromdate,
                  todate: this.$route.query.crefromdate,
                  fromdatedoc: this.$route.query.docfromdate,
                  todatedoc: this.$route.query.doctodate,
                  doctype: this.$route.query.doctype,
                  doc_no_start: this.$route.query.docnostart,
                  doc_no_end: this.$route.query.docnoend,
                  filename: this.$route.query.filename,
                  fileextension: this.$route.query.filetype,
                  sendername: this.$route.query.sellername,
                  sellerBranchid: this.$route.query.sellerbranchid,
                  sellerTaxid: this.$route.query.sellertaxid,
                  receiver_name: this.$route.query.buyername,
                  receiverTaxid: this.$route.query.buyertaxid,
                  receiverBranchid: this.$route.query.buyerbranchid
                }
              });
            } else {
              this.$router.push({ name: "myfiles" });
            }
          }
        });
      },
      fn_loginoneid() {
        location.assign(process.env.VUE_APP_ONEIDSSO);
      }
    },
    watch: {
      ssocode: function(val) {
        if (val === null) {
          console.log(val);
          this.loginsso(val);
        } else {
          // this.dialogsso = false
          this.loginsso(val);
        }
      }
    },
    mounted() {
      if (process.env.NODE_ENV === "production") {
        this.loginssooneid = true;
      } else {
        this.loginssooneid = false;
      }
  
      setTimeout(() => {
        //console.log(this.$route.query);
        this.changelang(localStorage.getItem("lang"));
        if ("authorizebydomain" in this.$route.query) {
          if ("onemailtoken" in this.$route.query) {
            console.log("l");
            localStorage.removeItem("color");
            let email = {
              business_email: this.$route.query.authorizebydomain,
              token: this.$route.query.onemailtoken
            };
            this.$store
              .dispatch("authorize_login_business_email_withtoken", email)
              .then(msg => {
                if (msg.status === "Access Granted") {
                  // ส่ง event ไปยัง Google Analytics
                  this.$gtag.event('login', {
                    event_category: "username",
                  });

                   console.log("ll");
                  if (this.$route.query.color !== undefined) {
                     console.log("lll");
                    localStorage.setItem("color", this.$route.query.color);
                  }
                  this.$router.push("/mydrive");
                  setTimeout(() => {
                    this.loader = false;
                  }, 500);
                } else {
                   console.log("llll");
                  this.loader = false;
                  Toast.fire({
                    icon: "error",
                    title: msg.msg
                  });
                }
              })
              .catch(error => {
                this.loader = false;
                console.log(error);
              });
          } else {
            console.log("lllll");
            localStorage.removeItem("color");
            let email = { business_email: this.$route.query.authorizebydomain };
            this.$store
              .dispatch("authorize_login_business_email_v2", email)
              .then(msg => {
                if (msg.status === "Access Granted") {
                  // ส่ง event ไปยัง Google Analytics
                  this.$gtag.event('login', {
                    event_category: "username",
                  });

                   //console.log("llllll");
                  if (this.$route.query.color !== undefined) {
                     //console.log("lllllll");
                    localStorage.setItem("color", this.$route.query.color);
                  }
                  this.$router.push("/mydrive");
                  setTimeout(() => {
                    this.loader = false;
                  }, 500);
                } else if (msg.code === "LN001") {
                  Toast.fire({
                    icon: "error",
                    title: "Username หรือ Password ไม่ถูกต้อง"
                  });
                  this.loader = false;
                } else if (msg.code === "ER401") {
                  Toast.fire({ icon: "error", title: "ไม่พบข้อมูลผู้ใช้งาน" });
                  this.loader = false;
                } else {
                  Toast.fire({ icon: "error", title: msg.msg });
                  this.loader = false;
                }
              })
              .catch(error => {
                this.loader = false;
                console.log(error);
              });
          }
        } else if ("code" in this.$route.query) {
          //console.log("llllllll");
          // this.$cookies.set('ssocode',this.$route.query.code);
          this.ssocode = this.$route.query.code;
          // clearInterval(this.intervalSSO)
        } else if ("goto" in this.$route.query) {
          this.loader = false;
        } else {
          this.$store
            .dispatch("authorize_checking")
            .then(msg => { 
              //console.log("l");
               //console.log(msg);
              if (msg.status === "Access Granted") {
                //console.log("lk");
                this.$router.push("/mydrive");
                // this.loader = false;
              } else if (msg.code === "LN001") {
                Toast.fire({
                  icon: "error",
                  title: "Username หรือ Password ไม่ถูกต้อง"
                });
                this.loader = false;
              } else {
                //console.log("l/");
                // Toast.fire({ icon: "error", title: msg.msg });
                this.loader = false;
                // this.dialogsso = true;
                // this.intervalSSO = setInterval(() => { this.ssocode = this.$cookies.get('ssocode') }, 800);
              }
            })
            .catch(err => {
              setTimeout(() => {
                this.dialog = true;
              }, 800);
              console.log(err);
              //  this.dialog = true;
              this.loader = false;
            });
        }
      }, 100);
    }
  };
  </script>
  
  <style></style>